import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Боксерський клуб "Воїн"
			</title>
			<meta name={"description"} content={"Будинок вашої мрії чекає"} />
			<meta property={"og:title"} content={"Головна | DreamHome Realty"} />
			<meta property={"og:description"} content={"Будинок вашої мрії чекає"} />
			<meta property={"og:image"} content={"https://techgrowthup.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://techgrowthup.com/img/6668733.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://techgrowthup.com/img/6668733.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://techgrowthup.com/img/6668733.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://techgrowthup.com/img/6668733.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://techgrowthup.com/img/6668733.png"} />
			<meta name={"msapplication-TileImage"} content={"https://techgrowthup.com/img/6668733.png"} />
			<meta name={"msapplication-TileColor"} content={"https://techgrowthup.com/img/6668733.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://techgrowthup.com/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					DreamHome Realty – Будинок вашої мрії чекає
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Ласкаво просимо до DreamHome Realty, де будинок вашої мрії стає реальністю. Ми з'єднуємо вас з ідеальною нерухомістю, яка відповідає вашому стилю життя та потребам. Наша місія полягає в наданні неперевершеного сервісу з професіоналізмом та чесністю.
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline2" margin="0 0 8px 0">
						Чому обирають нас?
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Експертна підготовка
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									наші тренери є досвідченими професіоналами в боксі, кікбоксингу та ММА, пропонуючи як технічні знання, так і реальний досвід.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Експертиза:
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
							Багаторічний досвід роботи в галузі гарантує, що ви отримаєте найкращі поради та послуги.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Персоналізоване обслуговування: 
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Ми пристосовуємо наші послуги до ваших конкретних вимог.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Різноманітна нерухомість: 
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Ми пропонуємо широкий вибір від міських квартир до розкішних маєтків.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://techgrowthup.com/img/2.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Будинок вашої мрії на відстані одного дзвінка
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					DreamHome Realty прагне втілити ваші мрії про нерухомість в реальність. Зв'яжіться з нами сьогодні, і давайте почнемо цю захоплюючу подорож разом.
				</Text>
				<Button margin="30px 0px 0px 0px" type="link" href="/contacts" text-decoration-line="initial">
					Контакти
				</Button>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});